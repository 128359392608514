@import "../../../app/styles/variables";

/********************** leave-review ************************/
.leave-review__container {
    padding: 96px 32px;
   
   .leave-review__content {
        background    : #F5F8FF;
        padding       : 44px 32px;
        border-radius : 8px;
        align-items   : center;
        justify-content: center;
        gap           : 44px;

        .leave-review_img {
            max-width: 288px;

            img {
                width: 100%;
            }
        }

        .leave-review__wrapper {
            max-width : 407px;
            width     : 100%;
        }
    
        h2 {
            font-size     : 30px;
            margin-bottom : 12px;
            color         : $pc-gray-900;
            font-family   : NotoSemiBold;
            line-height   : 38px;
        }
    
        h4 {
            line-height  : 28px;
            margin-bottom: 24px;
        }

        a {
            display: block;
            width: 201px;
        }
    }
}

   
@media (max-width: 1065px) {
    .leave-review__container {
        padding: 80px 24px;
    
        .leave-review__content {
            padding: 44px 24px;
            gap    : 16px;
        }
    }
}
   
@media (max-width: 768px) {
    .leave-review__container {
        .leave-review__content {
            flex-direction: column;

            .leave-review_img {
                max-width: 240px;
            }
    
            .leave-review__wrapper {
                max-width     : none;
                display       : flex;
                flex-direction: column;
                align-items   : center;

                h2 {
                    text-align   : center;
                    margin-bottom: 16px;
                }
                h4 {
                    text-align   : center;
                    margin-bottom: 32px;
                }
            }
        }
    }
}

@media (max-width: 606px) {
    .leave-review__container {
        padding: 64px 16px;
        .leave-review__wrapper {
            a {
                width: 100%;
            }
        }
    }
}