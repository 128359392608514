@import "../../../app/styles/variables";

.reviews__container {
  width: 100%;
}

.reviews__content {
  padding: 14px 32px;
  text-align: center;
  line-height: 44px;
}

.reviews__header {
  margin-bottom: 64px;

  h1 {
    color: $pc-gray-900;
    font-family: NotoSemiBold;
    margin-bottom: 24px;
  }

  h3 {
    line-height: 30px;
  }
}

.reviews__items {
  width: 100%;
  overflow: hidden;

  .reviews__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 88%;
    width: 376px;
    padding: 24px;
    text-align: left;
    background-color: $pc-gray-50;

    .date {
        color: $pc-gray-500;
    }

    .stars-rating {

        .stars__container {
            display: flex;
            gap: 10px;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }

    .title {
        font-family: NotoBold;
        font-size: 18px;
        line-height: 28px;
        color: $pc-gray-900;
        overflow: hidden;
        height: 28px;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

        &.link {
            text-align: start;
        }

        &:hover {
            color: $pc-accent-700;
        }
    }

    .text-container {
        position: relative;
        line-height: 24px;
      
      .truncated-text {
        display: -webkit-box;
        -webkit-line-clamp: 13;
        line-clamp: 13;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }
      
      .view-more-wrapper {
        display: inline;
        margin-left: 4px;
      }
      
      .link {
        display: inline;
        font-family: NotoRegular;
      }
    }

    .user-info {
        .avatar__wrapper {
            margin-right: 16px;
        }

        .author__name span {
            font-family: NotoBold;
            font-size: 18px;
            color: $pc-gray-900;
            line-height: 28px;
        }
        .author__text {
            font-family: NotoRegular;
            font-size: 16px;
            color: $pc-gray-500;
            line-height: 24px;
        }
    }
  }

  .ant-carousel .slick-prev::before,
  .ant-carousel .slick-next::before {
    color: transparent !important;
  }

  .ant-carousel {
    .slick-arrow {
      &.slick-disabled {
        display: none !important;
      }
    }

    .slick-prev {
      display: flex;
      background-color: $white;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border: 1px solid $pc-gray-200;

      &::after {
        color: $pc-gray-500;
        width: 16px;
        height: 16px;
        top: 0;
        bottom: 0;
        left: 6px;
        right: 0px;
        margin: auto;
        opacity: 1;
      }
    }

    .slick-next {
      display: flex;
      background-color: $white;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border: 1px solid $pc-gray-200;

      &::after {
        color: $pc-gray-500;
        width: 16px;
        height: 16px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 6px;
        margin: auto;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 768px) {
  .reviews__content {
    padding: 16px;
  }

  .reviews__header {
    h1 {
        
      margin-bottom: 24px;
    }

    h3 {
        margin-bottom: 32px;
    }
  }

  .reviews__content {
    .reviews__items {
      flex-direction: column;
    }
  }
}
