@import "../../../../app/styles/variables";

.free__container {
    align-self   : center;
    width        : 100%;

    .free__header {
        margin-bottom: 24px;

        .total__item {
            h2 {
                font-size: 36px;
                margin-top: 16px;
            }
        }
    }

    .free__empty {
        display        : flex;
        flex-wrap      : wrap;
        justify-content: center;
        gap            : 24px;

        .free__header {
            max-width    : 510px;
            margin-bottom: 0;
        }

        .promote-empty__content {
            margin: 32px 0;

            .list-content {
                gap          : 12px;
                margin-bottom: 16px;
                align-items  : flex-start;

                img {
                    padding: 5px;
                }

                h5 {
                    line-height  : 24px;
                }
            }
        }

        .link {
            width: fit-content;
        }
    }

    .admin__table {
        width: 100%;
        border-radius: 8px;
        border       : 1px solid $pc-gray-200;
        background   : $white;

        .admin__table-title {
            display: flex;
            justify-content: space-between;
            padding: 16px 24px;
            background-color: $pc-gray-25;
            border-radius: 12px 12px 0px 0px;
            border-bottom: 1px solid $pc-gray-200;

            .table-title__actions {
                gap: 24px;
            }

            .check__container {
                padding-left: 20px;
            }

            .button {
                padding: 0;
                line-height: 20px;
                .button__container-icon {
                    margin: 0;
                }
            }

            .total__wrapper {
                line-height: 20px;
                color: $pc-gray-400;
            }
        }

        .admin__table-header {
            display: flex;
        }
    }

    .admin__table-header, .admin__table-row {
        width: 100%;
    }

    .admin__table-header {
        display   : flex;
        height    : 44px;
        padding-right: 16px;
        justify-content: space-between;
        background-color: $pc-gray-50;
        
        .button {
            .ml-15 {
                margin-left: 4px;
            }

            .button__img-right {
                height   : 16px;
                width    : 16px;
            }
        }

        .product-name, .product-views, 
        .product-date, .product-status, 
        .product-downloads {
            line-height: 20px;
            color      : $pc-gray-500;
            font-family: NotoMedium;
            display    : flex;
            align-items: center;
            padding    : 12px 24px;
        }

        .product-name {
            width: 35%;
            padding-left: 56px;
        }
                
        .product-date {
            width: 16%;
        } 

        .product-views {
            width: 14%;
        }

        .product-status {
            width: 20%;

            h6 {
                line-height: 20px;
                color: $pc-gray-500;
                font-family: NotoMedium;
            }

            .button.link-gray {
                line-height: 20px;
                color: $pc-gray-500;
                font-family: NotoMedium;

                &:hover {
                    color: $pc-gray-500;
                }
            }
        }

        .product-downloads {
            width: 15%;
        }
    }

    .free__table-body {
        position  : relative;
        height    : calc(100vh - 528px);
        overflow  : auto;
        overflow-y: scroll;

        .loading-admin__container {
            z-index: 0;
        }
    }

    .product-name {
        width    : 35%;
        padding  : 6px 24px;

        .check__container {
            padding     : 10px;
            margin-right: 12px;
        }

        .modal-image {
            .modal__children {
                margin-right: 12px;
                min-width: 56px;
                max-width: 56px;
                height: 56px;
                border-radius: 4px;
                border: 1px solid $pc-gray-200;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 56px;
                    object-fit: cover;
                }
            }
        }

        .product-title {
            overflow: hidden;
            max-height: 50px;
        }

        h5 {
            line-height  : 24px;
            color        : $pc-gray-700;
            overflow     : hidden;
            text-overflow: ellipsis;
            text-wrap    : nowrap;
        }

        h6 {
            line-height: 20px;
            color      : $pc-gray-500;
        }
    }

    .product-date {
        width    : 16%;
        padding  : 12px 24px;

        h5 {
            font-family: NotoMedium;
            line-height: 24px;
            color      : $pc-gray-500;
        }

        h6 {
            line-height  : 20px;
            color        : $pc-gray-500;
        }
    }

    .product-views {
        padding   : 16px 24px;
        width: 14%;

        h6 {
            font-family  : NotoMedium;
        }
    }

    .product-status {
        width: 20%;
        padding: 16px 24px;
    }

    .product-downloads {
        width: 15%;
        padding: 16px 24px;

        h6 {
            font-family: NotoMedium;
        }
    }

    .admin__table-row {
        justify-content: space-between;
        height: 68px;
        cursor: auto;

        &:hover {
            background-color: transparent !important;
        }
    }

    .pagination__container {
        padding      : 16px 0;
        margin-bottom: 0;
    }
}